import React from 'react'

function Banner() {
return (
	<svg version="1.1" baseProfile="tiny" id="Capa_1" x="0px" y="0px" viewBox="0 0 768 331" overflow="visible">
	<g>
		<path fill="#F4B223" d="M0,189.2c58.5,27.3,128.3,27.8,194.7,25.1c66.4-2.7,134.7-7.7,197.8,10.3c53.2,15.1,98.8,45.6,150.3,64.5
		c70.7,25.9,152.2,29,225.2,8.5V11H0V189.2z"/>
		<path fill="#E36F1E" d="M0,178.2c58.5,27.3,128.3,27.8,194.7,25.1c66.4-2.7,134.7-7.7,197.8,10.3c53.2,15.1,98.8,45.6,150.3,64.5
		c70.7,25.9,152.2,29,225.2,8.5V0H0V178.2z"/>
	</g>
	</svg>
)
}

export default Banner
