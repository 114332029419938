import "../css/Screens.css";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useState, useEffect, useContext} from 'react';
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Anim01 from '../components/Anim01';
import soundFile from '../assets/audio/voice1.wav';
import Banner from '../components/Banner';
import Footer from '../components/Footer';
import LogoBlanco from '../assets/logo-blanco.png';
import { MuteContext } from '../utils/MuteContext';

function Dashboard() {
const [user, loading, error] = useAuthState(auth);
const [name, setName] = useState("");
const [mail, setMail] =useState("");
const navigate = useNavigate();
const [message1, setMessage1] = useState("");
const [message2, setMessage2] = useState("Hola soy Criss.");
const [message3, setMessage3] = useState("");
const [message4, setMessage4] = useState("");
const [message5, setMessage5] = useState("");
const [waitTime, setWaitTime] = useState(3000);
const [waitTime2, setWaitTime2] = useState(4000);
const [waitTime3, setWaitTime3] = useState(5000);

const { isMuted, toggleMute } = useContext(MuteContext);

useEffect(() => {
    if (isMuted) {
      window.document.querySelectorAll('audio,video').forEach(function(element) {
        element.muted = true;
      });
    } else {
      window.document.querySelectorAll('audio,video').forEach(function(element) {
        element.muted = false;
      });
    }
  }, [isMuted]);

const fetchUserName = async () => {
    //   const uid = user && user.uid;
    const uid = "tT7ug3xg19d0h9YWjTivaJ0cWcY2";
      const q = query(collection(db, "users"), where("uid", "==", uid));
      const querySnapshot = await getDocs(q);
      const userData = [];
      querySnapshot.forEach((doc) => {
        userData.push(doc.data());
      });
    
      return userData;
    };
    
useEffect(() => {
    // write query to get all data from users collection
    const q = query(collection(db, "users"));
    getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const data = doc.data();
        });
    });

fetchUserName()
    .then((userData) => {
      setName(userData[0].name);
      setMail(userData[0].email);
      console.log(userData[0].name, userData[0].email);
    })
    .catch((error) => {
      console.error(error);
    });
}, []);


useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    fetchUserName();
}, [user, loading]);


    //Revisa la orientación del dispositivo

useEffect(() => {
    function handleOrientationChange() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait) {
        alert("Por favor cambiar la orientación de la tableta");
        // document.body.style.display = "none";
      } else {
        // document.body.style.display = "block";
      }
    }
  
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
  
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  
//   Listen for changes in screen orientation
  useEffect(() => {
    function handleResize() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait) {
        alert("Por favor cambiar la orientación de la tableta");
        // document.body.style.display = "none";
      } else {
        // document.body.style.display = "block";
      }
    }
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

useEffect(() => {
    const timer = setTimeout(() => {
        setMessage1("Nos interesa");
        setMessage2("conocer tus comentarios");
        setMessage3("u opiniones.");
    setWaitTime(2000);
    }, waitTime);
    
    return () => clearTimeout(timer);
}, [message2, waitTime]);

useEffect(() => {
    const timer = setTimeout(() => {
        setMessage1("Todo lo que nos");
        setMessage2("compartas es");
        setMessage3("confidencial.");
        setMessage4("*Confidencial significa que no");
        setMessage5("compartiremos tu información.");
    setWaitTime2(2000);
    }, waitTime2);
    return () => clearTimeout(timer);
}, [message1, waitTime2]);

useEffect(() => {
        const timer = setTimeout(() => {
            setMessage1("Por favor");
            setMessage2("para empezar toca");
            setMessage3("el botón empieza aquí.");
            setMessage4("");
            setMessage5("");
        setWaitTime3(2000);
        }, waitTime3);
        return () => clearTimeout(timer);
    }, [message3, waitTime3]);

useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    fetchUserName();
}, [user, loading]);

return (
    <div className="dashboard__container dark-mode">
        <div className="banner__container">
            <Banner />
            <div className="logo__container" >
        <audio autoPlay preload="auto">
            <source src={soundFile} type="audio/wav" />
        </audio>
                <img className="" src={LogoBlanco} alt="Logo-care" />
            </div>
        </div>
        <div className="row">
                <div className="speech-bubble">
                    <svg baseProfile="tiny"
                        x="0px" y="0px" viewBox="0 0 312 206" overflow="visible">
                        <path fill="#E36F1E" d="M141.5,185.4c37.2,0,70.8-10.3,94.9-26.9c18.8,17.5,40.8,19.9,56,18.9c3.7-0.3,4.6-7.2,1.2-9.1
                        c-19.3-10.8-25.1-33-26.7-42.9c4.8-9.2,7.4-19.2,7.4-29.5c0-49.4-59.4-89.5-132.8-89.5C68.2,6.4,8.7,46.5,8.7,95.9
                        C8.7,145.4,68.2,185.4,141.5,185.4z"/>
                        <text x="45%" y="21%" textAnchor="middle" style={{ whiteSpace: "pre-line", fontSize: "1.4rem" }} dominantBaseline="middle" fill="#fff" className="speech-bubble__text">
                        {message1}
                        </text>
                        <text x="45%" y="37%" textAnchor="middle" style={{ whiteSpace: "pre-line", fontSize: "1.4rem" }} dominantBaseline="middle" fill="#fff" className="speech-bubble__text">
                        {message2}
                        </text>
                        <text x="45%" y="53%" textAnchor="middle" style={{ whiteSpace: "pre-line", fontSize: "1.4rem" }} dominantBaseline="middle" fill="#fff" className="speech-bubble__text">
                        {message3}
                        </text>
                        <text x="45%" y="63%" textAnchor="middle" style={{ whiteSpace: "pre-line", fontSize: "0.8rem" }} dominantBaseline="middle" fill="#fff" className="speech-bubble__text">
                        {message4}
                        </text>
                        <text x="45%" y="75%" textAnchor="middle" style={{ whiteSpace: "pre-line", fontSize: "0.8rem" }} dominantBaseline="middle" fill="#fff" className="speech-bubble__text">
                        {message5}
                        </text>
                    </svg>
                </div>            
            </div>
            <div className="row">
                <div className="anim01">
                    <Anim01 />
                </div>
            </div>
            <div className="row">
                <div className="btn__container">
                    <button className="btn-3" onClick={() => navigate('/screen2')}>
                        <h1>
                            Empieza aquí
                        </h1>
                    </button>
                </div>
            </div>
            <div className="row">
                <div className="footer-container">       
                <button className={isMuted ? 'mute-button muted' : 'mute-button'} onClick={() => toggleMute()}>{isMuted ? 'Activar sonido' : 'Silenciar'}</button>
                    <Footer/>
                </div>
        </div>
    </div>
);
}
export default Dashboard;