import React, { createContext, useState } from 'react';

export const MuteContext = createContext({
  isMuted: false,
  toggleMute: () => {},
});

// import React, { createContext, useState, useEffect } from 'react';

// export const MuteContext = createContext();

// export function MuteProvider(props) {
//   const [mute, setMute] = useState(false);

//   useEffect(() => {
//     const muteState = localStorage.getItem('muteState');
//     if (muteState === 'true') {
//       setMute(true);
//       window.document.querySelectorAll('audio,video').forEach(function(element) {
//         element.muted = true;
//       });
//     } else {
//       setMute(false);
//       window.document.querySelectorAll('audio,video').forEach(function(element) {
//         element.muted = false;
//       });
//     }
//   }, [localStorage.getItem('muteState')]);

//   function toggleSound() {
//     setMute(!mute);
//     window.document.querySelectorAll('audio,video').forEach(function(element) {
//       element.muted = !mute;
//     });

//     // Guardar el estado actual del mute en localStorage
//     localStorage.setItem('muteState', !mute);
//   }

//   return (
//     <MuteContext.Provider value={{ mute, toggleSound }}>
//       {props.children}
//     </MuteContext.Provider>
//   );
// }
