import {
    React,
    useState,
    useEffect,
    useRef,
    useNavigate,
    serverTimestamp,
    collection,
    addDoc,
    db,
    AudioRecorder,
    Anim01,
    Anim02,
    Anim03,
    Anim04,
    Anim05,
    Voice2,
    Voice3,
    Voice4,
    Voice5,
    Voice6,
    Voice7,
    EmojiEnojado,
    EmojiPensativo,
    EmojiTriste,
    EmojiFeliz,
    Banner,
    Footer,
    Link,
    LogoBlanco,
    useContext
} from "../utils/imports";
import AutoSubmitForm from "../utils/AutoSubmitForm";
import { MuteContext } from '../utils/MuteContext';

function Screen2() {
    const [name, setName] = useState("");
    const [age, setAge] = useState("0");
    const [id, setId] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [gender, setGender] = useState("");
    const [assistanceReceived, setAssistanceReceived] = useState("");
    const [assistanceType, setAssistanceType] = useState("");
    const [personalNote, setPersonalNote] = useState("");
    const [feedback, setFeedback] = useState("");
    const [generalNote, setGeneralNote] = useState("");
    const [selectedEmoji, setSelectedEmoji] = useState("");
    const [selectedEmojiText, setSelectedEmojiText] = useState("Selecciona tu estado de ánimo");
    const [privacy, setPrivacy] = useState();
    const [offlineData, setOfflineData] = useState([]);
    const [etapa, setEtapa] = useState(0);
    const [voiceNoteUrl, setVoiceNoteUrl] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showFooter, setShowFooter] = useState(true);
    const [showBubble, setShowBubble] = useState(true);
    const [moveAssistant, setMoveAssistant] = useState(false);
    const [mensaje, setMensaje] = useState("");
    const [mostrarMensaje, setMostrarMensaje] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [alertSubmit, setAlertSubmit] = useState(false);

    const handleAgeClick = () => {
        if (age === undefined || age === null || age === "0" || age < 0 || age > 120) {
            setModalOpen(true);
            setAge(undefined);
        }else if(age < 18) {
            setPrivacy(true)
            setEtapa(1);
        } else {
            setEtapa(1);
        }
    };
    
    const handleGenderClick = () => {
        switch (true) {
            case gender === "":
                setMensaje("Por favor completa el campo de género");
                setMostrarMensaje(true);
                break;
            case (age > 17 && assistanceReceived === ""):
                setMensaje("Por favor completa el campo de asistencia recibida");
                setMostrarMensaje(true);
                break;
            case (age > 17 && assistanceReceived === true && assistanceType === ""):
                setMensaje("Por favor completa el campo de tipo de asistencia recibida");
                setMostrarMensaje(true);
                break;
            default:
                setEtapa(3);
        }
    };


    // function handleGenderClick() {
    //     switch (true) {
    //         case gender === "":
    //             setMensaje("Por favor completa el campo de género");
    //             setShowAlert(true);
    //             break;
    //             case assistanceReceived === "":
    //                 setMensaje("Por favor completa el campo de asistencia recibida");
    //                 setMostrarMensaje(true);
    //                 break;
    //             case (assistanceReceived === true && assistanceType === ""):
    //                 setMensaje("Por favor completa el campo de tipo de asistencia recibida");
    //                 setMostrarMensaje(true);
    //                 break;
    //         default:
    //             setEtapa(3);
    //             break;
    //     }
    // }

    const aceptarMensaje = () => {
        setMostrarMensaje(false);
    };

    //Related to the emoji function
    let emoji = ""

    const handleTextAreaClick = () => {
            setSelectedEmojiText("");
    }
        const handleEmojiClick = (emoji) => {
            setSelectedEmoji(emoji);
        };

    const navigate = useNavigate();

    const validatePhoneNumber = (phoneNumber) => {
        const regex = /^\+?\d{10,14}$/; // patrón que permite números de 10 a 14 dígitos, y que también acepta el prefijo internacional (+)
        return regex.test(phoneNumber);
    };

const { isMuted, toggleMute } = useContext(MuteContext);

useEffect(() => {
    if (isMuted) {
      window.document.querySelectorAll('audio,video').forEach(function(element) {
        element.muted = true;
      });
    } else {
      window.document.querySelectorAll('audio,video').forEach(function(element) {
        element.muted = false;
      });
    }
  }, [isMuted]);

    //Revisa la orientación del dispositivo

useEffect(() => {
    function handleOrientationChange() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait) {
        alert("Por favor cambiar la orientación de la tableta");
        // document.body.style.display = "none";
      } else {
        // document.body.style.display = "block";
      }
    }
  
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
  
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  
  // Listen for changes in screen orientation
  useEffect(() => {
    function handleResize() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait) {
        alert("Por favor cambiar la orientación de la tableta");
        // document.body.style.display = "none";
      } else {
        // document.body.style.display = "block";
      }
    }
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

    useEffect(() => {
        // Verificar si hay datos almacenados en localStorage al cargar la página
        const storedData = localStorage.getItem("offlineData");
        if (storedData) {
        setOfflineData(JSON.parse(storedData));
        }
    }, []);

    useEffect(() => {
        const handleOnline = () => {
        // Enviar datos almacenados en localStorage si hay conexión a internet
        if (navigator.onLine && offlineData.length > 0) {
            offlineData.forEach(async (data) => {
            try {
                await addDoc(collection(db, "contacts"), {
                ...data,
                timestamp: serverTimestamp(),
                });
                // alert("Su aporte fué enviado correctamente!");
                setOfflineData([]);
            } catch (error) {
                console.error("Error al enviar su aporte: ", error);
            }
            });
        }
    };
    window.addEventListener("online", handleOnline);
    return () => {
    window.removeEventListener("online", handleOnline);
    };
}, [offlineData]);

const handleSubmit = async (event) => {
    setIsSubmitting(true);
    // event.preventDefault();

    // Enviar el formulario
    const data = {
    name,
    age,
    privacy,
    id,
    phoneNumber,
    gender,
    assistanceReceived,
    assistanceType,
    personalNote,
    generalNote,
    feedback,
    voiceNoteUrl,
    selectedEmoji,
    emojiText:selectedEmojiText
    };

    if (navigator.onLine) {
    // Send the data to server if internet is reestablished
    try {
        await addDoc(collection(db, "contacts"), {
        ...data,
        timestamp: serverTimestamp(),
    });
        setName("");
        setAge("");
        setPrivacy(true);
        setId("");
        setPhoneNumber("");
        setGender("");
        setAssistanceReceived(false);
        setAssistanceType("");
        setPersonalNote("");
        setFeedback("");
        setGeneralNote("");
        setSelectedEmoji("");
        setSelectedEmojiText("");
        setVoiceNoteUrl("");
        setAlertSubmit(true);
        
        await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 sec before showing msg
        navigate("/dashboard");
        
    } catch (error) {
        console.error("Error al guardar el contacto: ", error);
    }
    } else {
    // Stores data in localstorage if connection is lost
    setOfflineData([
        ...offlineData,
        {
        ...data,
        timestamp: new Date().toISOString(),
        },
    ]);
    alert(
        "No hay conexión a internet, se guardarán los datos en el dispositivo y se enviarán automáticamente cuando se recupere la conexión."
    );
    setName("");
    setAge("");
    setPrivacy(false);
    setId("");
    setPhoneNumber("");
    setGender("");
    setAssistanceReceived(false);
    setAssistanceType("");
    setPersonalNote("");
    setFeedback("");
    setGeneralNote("");
    setSelectedEmoji("");
    setSelectedEmojiText("");
    setVoiceNoteUrl("");
    }

    setIsSubmitting(false);
    navigate("/dashboard");
    
};

const mediaRecorder = useRef(null);

useEffect(() => {
    const handleResize = () => {
    if (window.innerHeight < height) {
        setShowFooter(false);
        setShowBubble(false);
        setMoveAssistant(false);
    } else {
        setShowFooter(true);
        setShowBubble(true);
        setMoveAssistant(true);
    }
};

const height = window.innerHeight;

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
}, []);

const validarFormulario = () => {
    switch (true) {
        case name === "":
            setMensaje("Por favor completa el campo de nombre");
            setMostrarMensaje(true);
            break;
        case name.length > 40:
            setMensaje(
            "El campo de nombre no puede contener más de 40 caracteres"
            );
            setMostrarMensaje(true);
            break;
        case id === "":
            setMensaje("Por favor completa el campo de ID");
            setMostrarMensaje(true);
            break;
        case id.length > 13:
            setMensaje("El campo de ID no puede contener más de 13 caracteres");
            setMostrarMensaje(true);
            break;
        case gender === "":
            setMensaje("Por favor completa el campo de género");
            setMostrarMensaje(true);
            break;
        case assistanceReceived === "":
            setMensaje("Por favor completa el campo de asistencia recibida");
            setMostrarMensaje(true);
            break;
        case (assistanceReceived === true && assistanceType === ""):
            setMensaje("Por favor completa el campo de tipo de asistencia recibida");
            setMostrarMensaje(true);
            break;
        case !validatePhoneNumber(phoneNumber):
            setMensaje("Número de teléfono inválido");
            setMostrarMensaje(true);
            break;
        default:
            setEtapa(3);
    }
};

return (
    <div className="dashboard__container">
    <div className="banner__container">
        <Banner />
        <div className="logo__container">
            <img className="" src={LogoBlanco} alt="Logo-care" />
        </div>
    </div>
    <AutoSubmitForm
        onSubmit={handleSubmit}
        timeout={600000}
    >
        {/* PRIMER RENDERIZADO CONDICIONAL */}
        {etapa === 0 && (
        <>
            {!isMuted &&(
                <audio autoPlay preload="auto">
                    <source src={Voice2} type="audio/wav" />
                </audio>
            )}
            <div className="row">
            <div className="speech-bubble-reversed">
                <svg
                version="1.1"
                baseProfile="tiny"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 203 106"
                overflow="visible"
                >
                <path
                    fill="#E36F1E"
                    d="M109.9,10.2c-24.1,0-46,5.2-61.6,13.5c-12.2-8.8-26.5-10-36.4-9.5c-2.4,0.1-3,3.6-0.8,4.6
                                c12.5,5.4,16.3,16.5,17.4,21.5c-3.1,4.6-4.8,9.6-4.8,14.8c0,24.8,38.6,44.9,86.3,44.9c47.6,0,86.3-20.1,86.3-44.9
                                C196.1,30.3,157.5,10.2,109.9,10.2z"
                />
                <text
                    x="55%"
                    y="50%"
                    textAnchor="middle"
                    style={{
                    whiteSpace: "pre-line",
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                >
                    ¿Cuál es tu edad?
                </text>
                </svg>
            </div>
            </div>
            <div className="row">
            <div className="anim02">
                <Anim05 />
            </div>
            </div>
            <div className="row">
            <div className="input__container">
                <label>
                <input
                    type="number"
                    placeholder="Ingresa tu edad"
                    // value={age || ""}
                    min="0"
                    max="120"
                    required
                    style={{
                    border: "4px solid #f4b122",
                    borderRadius: "45px",
                    width: "300%",
                    height: "200%",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "25px",
                    }}
                    onChange={(event) => setAge(event.target.value)}
                />
                </label>
            </div>
            </div>
            <div className="row">
            <div className="btn__container2">
                {modalOpen && (
                    <div onClose={() => setModalOpen(false)} className="alert-message-box">
                        <p>Debes ingresar una edad válida</p>
                        <button className="alert-message-button" onClick={() => setModalOpen(false)}>Aceptar</button>
                    </div>
                )}
                <button
                onClick={handleAgeClick}
                style={{
                    background: "none",
                    border: "none",
                    padding: "0",
                    cursor: "pointer",
                }}
                >
                <svg
                    version="1.1"
                    baseProfile="tiny"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 218 120"
                    overflow="visible"
                    width="250px"
                    height="180px"
                >
                    <path
                    fill="#F4B223"
                    stroke="#E36F1E"
                    strokeWidth="5"
                    strokeMiterlimit="10"
                    d="M135.4,12.7c-6.2,0-11.3,5.1-11.3,11.3v6.4
                                        l-95.6-4.5c-0.2,0-0.4,0-0.5,0c-6.2,0-11.3,5.1-11.3,11.3v45.7c0,6.2,5.1,11.3,11.3,11.3c0.2,0,0.4,0,0.5,0l95.6-4.5V96
                                        c0,6.2,5.1,11.3,11.3,11.3c2.2,0,4.4-0.6,6.2-1.9l54.6-36c3.2-2.1,5.1-5.6,5.1-9.4c0-3.8-1.9-7.3-5.1-9.4l-54.6-36
                                        C139.8,13.3,137.7,12.7,135.4,12.7z"
                    />
                    <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    style={{
                        whiteSpace: "pre-line",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                        fill: "#000",
                        dominantBaseline: "middle",
                        cursor: "pointer",
                        padding: "1px",
                    }}
                    className="speech-bubble__text"
                    >
                    Siguiente
                    </text>
                </svg>
                </button>
            </div>
            </div>
        </>
        )}
        {/* SEGUNDO RENDERIZADO CONDICIONAL*/}
        {etapa === 1 && age > 17 && (
        <>
            
            {!isMuted &&(

                <audio autoPlay preload="auto">
                    <source src={Voice3} type="audio/wav" />
                </audio>
            )}
            <div className="row">
            <div className="speech-bubble-reversed">
                <svg
                version="1.1"
                baseProfile="tiny"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 203 106"
                overflow="visible"
                >
                <path
                    fill="#E36F1E"
                    d="M109.9,10.2c-24.1,0-46,5.2-61.6,13.5c-12.2-8.8-26.5-10-36.4-9.5c-2.4,0.1-3,3.6-0.8,4.6
                                c12.5,5.4,16.3,16.5,17.4,21.5c-3.1,4.6-4.8,9.6-4.8,14.8c0,24.8,38.6,44.9,86.3,44.9c47.6,0,86.3-20.1,86.3-44.9
                                C196.1,30.3,157.5,10.2,109.9,10.2z"
                />
                <text
                    x="55%"
                    y="30%"
                    textAnchor="middle"
                    style={{
                    whiteSpace: "pre-line",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                >
                    ¿Cómo deseas
                </text>
                <text
                    x="55%"
                    y="45%"
                    textAnchor="middle"
                    style={{
                    whiteSpace: "pre-line",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                >
                    compartir tu
                </text>
                <text
                    x="55%"
                    y="65%"
                    textAnchor="middle"
                    style={{
                    whiteSpace: "pre-line",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                >
                    información?
                </text>
                </svg>
            </div>
            </div>
            <div className="row">
            <div className="anim03">
                <Anim03 />
            </div>
            </div>
            <label className="btn__container3">
            <select
                defaultValue=""
                required
                style={{
                border: "4px solid #f4b122",
                borderRadius: "45px",
                width: "120%",
                padding: "20px",
                textAlign: "center",
                bottom: "0",
                fontWeight: "bold",
                fontSize: "25px",
                }}
                onChange={(event) => setPrivacy(event.target.value === "true")}
            >
                <option style={{ fontSize: "1rem" }} value="" disabled>
                Selecciona una opción
                </option>
                <option style={{ fontSize: "1.2rem" }} value={true}>
                De manera anónima
                </option>
                <option style={{ fontSize: "1.2rem" }} value={false}>
                Con mis datos de contacto
                </option>
            </select>
            </label>

            <div className="row">
            <div className="btn__container2">
                <button
                onClick={() => {
                    setEtapa(2);
                }}
                disabled={!privacy} // Deshabilitar el botón si no se seleciona la privacidad
                style={{
                    background: "none",
                    border: "none",
                    padding: "0",
                    cursor: "pointer",
                }}
                >
                <svg
                    version="1.1"
                    baseProfile="tiny"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 218 120"
                    overflow="visible"
                    width="280px"
                    height="180px"
                >
                    <path
                    fill="#F4B223"
                    stroke="#E36F1E"
                    strokeWidth="5"
                    strokeMiterlimit="10"
                    d="M135.4,12.7c-6.2,0-11.3,5.1-11.3,11.3v6.4
                                        l-95.6-4.5c-0.2,0-0.4,0-0.5,0c-6.2,0-11.3,5.1-11.3,11.3v45.7c0,6.2,5.1,11.3,11.3,11.3c0.2,0,0.4,0,0.5,0l95.6-4.5V96
                                        c0,6.2,5.1,11.3,11.3,11.3c2.2,0,4.4-0.6,6.2-1.9l54.6-36c3.2-2.1,5.1-5.6,5.1-9.4c0-3.8-1.9-7.3-5.1-9.4l-54.6-36
                                        C139.8,13.3,137.7,12.7,135.4,12.7z"
                    />
                    <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    style={{
                        whiteSpace: "pre-line",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                        fill: "#000",
                        dominantBaseline: "middle",
                        cursor: "pointer",
                        padding: "1px",
                    }}
                    className="speech-bubble__text"
                    >
                    Siguiente
                    </text>
                </svg>
                </button>
            </div>
            </div>
        </>
        )}

        {/* SEGUNDO BIS RENDERIZADO CONDICIONAL*/}
        {((etapa === 1 && age < 18) || etapa === 2) && (
        <>
            
            {!isMuted &&(

                <audio autoPlay preload="auto">
                    <source src={Voice4} type="audio/wav" />
                </audio>
            )}
            {/* TERCERO RENDERIZADO CONDICIONAL PARA PRIVACIDAD */}
            {!privacy && (
            <div className="input__fields__container">
                <div className="input__container2">
                    <label htmlFor="name">
                        <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        placeholder="Escriba su nombre"
                        required
                        />
                    </label>
                </div>
                    {!name && <span style={{ color: 'red'}}>Este campo es requerido</span>}
                <div className="input__container2">
                <label htmlFor="id">
                    <input
                    type="text"
                    id="id"
                    value={id}
                    onChange={(event) => setId(event.target.value)}
                    placeholder="Escriba su CI"
                    required
                    />
                </label>
                </div>
                    {!id && <span style={{ color: 'red'}}>Este campo es requerido</span>}

                <div className="input__container2">
                    <label htmlFor="phoneNumber">
                        <input
                        type="text"
                        id="phoneNumber"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        placeholder="Escriba su teléfono"
                        />
                    </label>
                </div>
                    {!phoneNumber && <span style={{ color: 'red'}}>Este campo es requerido</span>}

            <div className="input__container2">
                <label htmlFor="genero">
                    <select
                    value={gender}
                    required
                    defaultValue=""
                    style={{
                        border: "4px solid #f4b122",
                        borderRadius: "32px",
                        width: "80%",
                        padding: "15px",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "20px",
                        color: "#858585",
                    }}
                    onChange={(event) => setGender(event.target.value)}
                    >
                    <option value="" disabled>
                        Seleccione su género
                    </option>
                    <option value="masculino">Masculino</option>
                    <option value="femenino">Femenino</option>
                    <option value="LGTBIQ+">LGTBIQ+</option>
                    <option value="No desea especificar">
                        No desea especificar
                    </option>
                    </select>
                </label>
            </div>
            
            {!gender && <span style={{ color: 'red'}}>Este campo es requerido</span>}
            <div className="input__container2">
            <label htmlFor="asistencia">
                <select
                defaultValue=""
                style={{
                    border: "4px solid #f4b122",
                    borderRadius: "32px",
                    width: "80%",
                    padding: "15px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#858585",
                }}
                required
                onChange={(event) =>
                    setAssistanceReceived(event.target.value === "true")
                }
                >
                <option
                    required
                    value=""
                    disabled>
                    ¿Ha recibido algun tipo de asistencia o atención?
                </option>
                <option value={true}>Si</option>
                <option value={false}>No</option>
                </select>
            </label>
            </div>
            {assistanceReceived === '' && (
  <span style={{ color: 'red' }}>Este campo es requerido</span>
)}

            {assistanceReceived && (
            <div className="input__container2">
                <label htmlFor="asistencia-tipo">
                <select
                    required
                    defaultValue=""
                    style={{
                    border: "4px solid #f4b122",
                    borderRadius: "32px",
                    width: "80%",
                    padding: "15px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#858585",
                    }}
                    onChange={(event) => setAssistanceType(event.target.value)}
                >
                    <option value="" disabled>
                    ¿Qué tipo de asistencia recibió?
                    </option>
                    <option value="Asistencia Legal, social, psicosocial, talleres (Servicios).">
                    Fortalecimiento de capacidades (capacitaciones, sensibilización, otros)
                    </option>
                    <option value="Asistencia Legal, social, psicosocial, talleres (Servicios).">
                    Asistencia Legal, social, psicosocial, talleres
                    (Servicios).
                    </option>
                    <option value="Kits, copas menstruales, etc (Productos).">
                    Kits, copas menstruales, etc (Productos).
                    </option>
                    <option value="Dinero, Váucher de Salud, alojamiento o arriendo.">
                    Dinero, Váucher de Salud, alojamiento o arriendo.
                    </option>
                </select>
                </label>
                {(assistanceReceived === true && !assistanceType) && <span style={{ color: 'red'}}>Este campo es requerido</span>}
            </div>
            
            )}
            </div>
            )}
            {/* This case is when and if the user selected to keep is input private  */}
            {privacy && (
                <div>
                    <div className="input__container2 space">
                        <label htmlFor="genero">
                            <select
                            value={gender}
                            defaultValue=""
                            style={{
                                border: "4px solid #f4b122",
                                borderRadius: "32px",
                                width: "80%",
                                padding: "15px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                color: "#858585",
                            }}
                            required
                            onChange={(event) => setGender(event.target.value)}
                            >
                            <option value="" disabled>
                                Seleccione su género
                            </option>
                            <option value="masculino">Masculino</option>
                            <option value="femenino">Femenino</option>
                            <option value="LGTBIQ+">LGTBIQ+</option>
                            <option value="No desea especificar">
                                No desea especificar
                            </option>
                            </select>
                        </label>
                    </div>
                    
                    {!gender && <span style={{ color: 'red'}}>Este campo es requerido</span>}
                    {age>17 &&(
                        <>
                    <div className="input__container2">
                        <label htmlFor="asistencia">
                            <select
                            defaultValue=""
                            style={{
                                border: "4px solid #f4b122",
                                borderRadius: "32px",
                                width: "80%",
                                padding: "15px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                color: "#858585",
                            }}
                            required
                            onChange={(event) =>
                                setAssistanceReceived(event.target.value === "true")
                            }
                            >
                            <option value="" disabled>
                                ¿Ha recibido algun tipo de asistencia o atención?
                            </option>
                            <option value={true}>Si</option>
                            <option value={false}>No</option>
                            </select>
                        </label>
                    </div>
                    
                        {assistanceReceived === '' && (
                                <span style={{ color: 'red' }}>Este campo es requerido</span>
                        )}
                    {assistanceReceived && (
                    <div className="input__container2">
                        <label htmlFor="asistencia-tipo">
                            <select
                                defaultValue=""
                                style={{
                                border: "4px solid #f4b122",
                                borderRadius: "32px",
                                width: "80%",
                                padding: "15px",
                                textAlign: "center",
                                fontWeight: "bold",
                                fontSize: "20px",
                                color: "#858585",
                                }}
                                required
                                onChange={(event) => setAssistanceType(event.target.value)}
                            >
                                <option value="" disabled>
                                ¿Qué tipo de asistencia recibió?
                                </option>
                                <option value="Asistencia Legal, social, psicosocial, talleres (Servicios).">
                                Fortalecimiento de capacidades (capacitaciones, sensibilización, otros)
                                </option>
                                <option value="Asistencia Legal, social, psicosocial, talleres (Servicios).">
                                Asistencia Legal, social, psicosocial, talleres
                                (Servicios).
                                </option>
                                <option value="Kits, copas menstruales, etc (Productos).">
                                Kits, copas menstruales, etc (Productos).
                                </option>
                                <option value="Dinero, Váucher de Salud, alojamiento o arriendo.">
                                Dinero, Váucher de Salud, alojamiento o arriendo.
                                </option>
                            </select>
                        </label>
                        
                    {(assistanceReceived === true && !assistanceType) && <span style={{ color: 'red'}}>Este campo es requerido</span>}
                    </div>
                    )}
                    </>
                    )}
                </div>
            )}

            {showBubble && (
            <div className="speech-bubble-3">
                <svg
                version="1.1"
                baseProfile="tiny"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 231 166"
                overflow="visible"
                >
                <path
                    fill="#e36f1e"
                    d="m 159.17143,186.65714 c -36.1,0 -68.800001,-10.06414 -92.300001,-26.25428 -18.3,17.06528 -39.7,19.399 -54.5,18.378 -3.6000004,-0.29172 -4.5000004,-7.00115 -1.2,-8.89729 18.7,-10.64757 24.4,-32.23443 26,-42.00686 -4.6,-8.89728 -7.2,-18.66971 -7.2,-28.733853 0,-48.278715 57.8,-87.514286 129.100001,-87.514286 71.3,0 129.1,39.235571 129.1,87.514286 0.1,48.278713 -57.7,87.514283 -129,87.514283 z"
                />

                <text
                    x="70%"
                    y="52%"
                    textAnchor="middle"
                    style={{ whiteSpace: "pre-line", fontSize: "1.2rem" }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                >
                    Por favor completa con
                </text>
                <text
                    x="70%"
                    y="70%"
                    textAnchor="middle"
                    style={{ whiteSpace: "pre-line", fontSize: "1.2rem" }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                >
                    tu información personal
                </text>
                </svg>
            </div>
            )}
            <div className="row">
            {privacy && (
                <div className="anim04">
                    <Anim04 />
                </div>
            )}
            {!privacy && (
                <>{moveAssistant ? (
                    <div className="anim04-3">
                        <Anim04 />
                    </div>
                ) : (
                    <div className="anim04-3-small">
                        <Anim04 />
                    </div>
                )}
                <div className="row">
                    {mostrarMensaje && (
                    <div className="alert-message-box">
                        <p>{mensaje}</p>
                        <button className="alert-message-button" onClick={aceptarMensaje}>Aceptar</button>
                    </div>
                    )}
                    <div className="btn__container4">
                        <div onClick={validarFormulario}>
                        <svg
                        version="1.1"
                        baseProfile="tiny"
                        id="Capa_1"
                        x="0px"
                        y="0px"
                        viewBox="0 0 218 120"
                        overflow="visible"
                        width="240px"
                        height="140px"
                        >
                        <path
                            fill="#F4B223"
                            stroke="#E36F1E"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                            d="M135.4,12.7c-6.2,0-11.3,5.1-11.3,11.3v6.4
                                                    l-95.6-4.5c-0.2,0-0.4,0-0.5,0c-6.2,0-11.3,5.1-11.3,11.3v45.7c0,6.2,5.1,11.3,11.3,11.3c0.2,0,0.4,0,0.5,0l95.6-4.5V96
                                                    c0,6.2,5.1,11.3,11.3,11.3c2.2,0,4.4-0.6,6.2-1.9l54.6-36c3.2-2.1,5.1-5.6,5.1-9.4c0-3.8-1.9-7.3-5.1-9.4l-54.6-36
                                                    C139.8,13.3,137.7,12.7,135.4,12.7z"
                        />
                        <text
                            x="50%"
                            y="50%"
                            textAnchor="middle"
                            style={{
                            whiteSpace: "pre-line",
                            fontSize: "1.4rem",
                            fontWeight: "bold",
                            fill: "#000",
                            dominantBaseline: "middle",
                            cursor: "pointer",
                            padding: "1px",
                            }}
                        >
                            Siguiente
                        </text>
                        </svg>
                    </div>
                    </div>
                </div>
                </>
            )}

            {privacy && (
                <>
                <div className="row">
                {mostrarMensaje && (
                    <div className="alert-message-box">
                        <p>{mensaje}</p>
                        <button className="alert-message-button" onClick={aceptarMensaje}>Aceptar</button>
                    </div>
                    )}
                    {/* <div>
                        <div className="alert-message-box" style={{ display: showAlert ? 'flex' : 'none' }}>
                            <p>Por favor completa el campo de género y el de asistencia recibida</p>
                            <button className="alert-message-button" onClick={() => setShowAlert(false)}>Aceptar</button>
                        </div>
                    </div> */}
                        <div className="btn__container4">
                            <div onClick={handleGenderClick}>
                            <svg
                            version="1.1"
                            baseProfile="tiny"
                            id="Capa_1"
                            x="0px"
                            y="0px"
                            viewBox="0 0 218 120"
                            overflow="visible"
                            width="300px"
                            height="200px"
                            >
                            <path
                                fill="#F4B223"
                                stroke="#E36F1E"
                                strokeWidth="5"
                                strokeMiterlimit="10"
                                d="M135.4,12.7c-6.2,0-11.3,5.1-11.3,11.3v6.4
                                                        l-95.6-4.5c-0.2,0-0.4,0-0.5,0c-6.2,0-11.3,5.1-11.3,11.3v45.7c0,6.2,5.1,11.3,11.3,11.3c0.2,0,0.4,0,0.5,0l95.6-4.5V96
                                                        c0,6.2,5.1,11.3,11.3,11.3c2.2,0,4.4-0.6,6.2-1.9l54.6-36c3.2-2.1,5.1-5.6,5.1-9.4c0-3.8-1.9-7.3-5.1-9.4l-54.6-36
                                                        C139.8,13.3,137.7,12.7,135.4,12.7z"
                            />
                            <text
                                x="50%"
                                y="50%"
                                textAnchor="middle"
                                style={{
                                whiteSpace: "pre-line",
                                fontSize: "1.4rem",
                                fontWeight: "bold",
                                fill: "#000",
                                dominantBaseline: "middle",
                                cursor: "pointer",
                                padding: "1px",
                                }}
                            >
                                Siguiente
                            </text>
                        </svg>
                    </div>
                    </div>
                </div>
                </>
            )}
            </div>
        </>
        )}

        {/* CUARTO RENDERIZADO CONDICIONAL*/}

        {etapa === 3 && age > 17 && (
        <>
            {!isMuted &&(
            <audio autoPlay preload="auto">
            <source src={Voice5} type="audio/wav" />
            </audio>
            )}

            <div className="speech-bubble-4">
            <svg
                version="1.1"
                baseProfile="tiny"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 203 106"
                overflow="visible"
            >
                <path
                fill="#E36F1E"
                d="M109.9,10.2c-24.1,0-46,5.2-61.6,13.5c-12.2-8.8-26.5-10-36.4-9.5c-2.4,0.1-3,3.6-0.8,4.6
                                c12.5,5.4,16.3,16.5,17.4,21.5c-3.1,4.6-4.8,9.6-4.8,14.8c0,24.8,38.6,44.9,86.3,44.9c47.6,0,86.3-20.1,86.3-44.9
                                C196.1,30.3,157.5,10.2,109.9,10.2z"
                />

                <text
                x="55%"
                y="50%"
                textAnchor="middle"
                style={{ whiteSpace: "pre-line", fontSize: "0.9rem" }}
                dominantBaseline="middle"
                fill="#fff"
                className="speech-bubble4__text"
                >
                ¿Qué deseas contarnos?
                </text>
            </svg>
            </div>
            <div className="row">
            <div className="anim05">
                <Anim04 />
            </div>
            </div>
            <div className="input__fields__container2">
            <div className="input__container3">
                <label htmlFor="nota-personal">
                <select
                    defaultValue=""
                    style={{
                    border: "4px solid #f4b122",
                    borderRadius: "32px",
                    width: "120%",
                    padding: "15px",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#858585",
                    }}
                    required
                    onChange={(event) => {
                    setPersonalNote(event.target.value);
                    }}
                >
                    <option value="" disabled>
                    Selecciona una opción
                    </option>
                    <option value="Agradecimiento">Agradecimiento</option>
                    <option value="Sugerencia">Sugerencia</option>
                    <option value="Queja">Queja</option>
                </select>
                </label>
                <div
                className="input__container2"
                style={{ position: "relative" }}
                >
                <label>
                    <textarea
                    value={generalNote}
                    onChange={(event) => setGeneralNote(event.target.value)}
                    placeholder="Escribe o deja una nota de voz detallando sobre la opción que seleccionaste"
                    style={{ width: "100%" }}
                    />
                </label>
                <div
                    style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                    }}
                >
                    <AudioRecorder onAudioRecorded={setVoiceNoteUrl} />
                </div>
            </div>
        </div>
    </div>
        <div className="row">
            <div className="btn__container5">
                <button
                onClick={()=>(setEtapa(4))}
                disabled={personalNote === "" || (generalNote === "" && voiceNoteUrl === "" )} // Deshabilitar el botón si no se completa una nota
                style={{
                    background: "none",
                    border: "none",
                    padding: "0",
                    cursor: "pointer",
                }}
                >
                <svg
                    version="1.1"
                    baseProfile="tiny"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 218 120"
                    overflow="visible"
                    width="200px"
                    height="100px"
                >
                    <path
                    fill="#F4B223"
                    stroke="#E36F1E"
                    strokeWidth="5"
                    strokeMiterlimit="10"
                    d="M135.4,12.7c-6.2,0-11.3,5.1-11.3,11.3v6.4
                                            l-95.6-4.5c-0.2,0-0.4,0-0.5,0c-6.2,0-11.3,5.1-11.3,11.3v45.7c0,6.2,5.1,11.3,11.3,11.3c0.2,0,0.4,0,0.5,0l95.6-4.5V96
                                            c0,6.2,5.1,11.3,11.3,11.3c2.2,0,4.4-0.6,6.2-1.9l54.6-36c3.2-2.1,5.1-5.6,5.1-9.4c0-3.8-1.9-7.3-5.1-9.4l-54.6-36
                                            C139.8,13.3,137.7,12.7,135.4,12.7z"
                    />
                    <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    style={{
                        whiteSpace: "pre-line",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                        fill: "#000",
                        dominantBaseline: "middle",
                        cursor: "pointer",
                        padding: "1px",
                    }}
                    className="speech-bubble__text"
                    >
                    Siguiente
                    </text>
                </svg>
                </button>
            </div>
            </div>
        </>
        )}

        {/* QUINTO RENDERIZADO CONDICIONAL*/}

        {etapa === 3 && age < 18 && (
        <>
        {!isMuted &&(
            <audio autoPlay preload="auto">
                <source src={Voice6} type="audio/wav" />
            </audio>
        )}

            <div className="speech-bubble-5">
            <svg
                version="1.1"
                baseProfile="tiny"
                id="Capa_1"
                x="0px"
                y="0px"
                viewBox="0 0 231 166"
                overflow="visible"
            >
                <path
                fill="#E36F1E"
                d="M6.6,63.6c0-33.3,51.5-60.3,115-60.3s115,27,115,60.3s-51.5,60.3-115,60.3c-21.6,0-41.9-3.1-59.2-8.6
                                l-20.7,17.9c-4.2,3.6-10.6,0.4-10.3-5.1l1.4-26.2C16.4,91.5,6.6,78.2,6.6,63.6z"
                />
                <text
                x="50%"
                y="22%"
                textAnchor="middle"
                style={{ whiteSpace: "pre-line", fontSize: "1.2rem" }}
                dominantBaseline="middle"
                fill="#fff"
                className="speech-bubble__text"
                >
                ¿Cómo te sientes
                </text>
                <text
                x="50%"
                y="40%"
                textAnchor="middle"
                style={{ whiteSpace: "pre-line", fontSize: "1.2rem" }}
                dominantBaseline="middle"
                fill="#fff"
                className="speech-bubble__text"
                >
                en el espacio de
                </text>
                <text
                x="50%"
                y="60%"
                textAnchor="middle"
                style={{
                    whiteSpace: "pre-line",
                    fontWeight: "bold",
                    fontSize: "1.5rem",
                }}
                dominantBaseline="middle"
                fill="#fff"
                className="speech-bubble__text"
                >
                CARE?
                </text>
            </svg>
            </div>
            <div className="row2">
            <div className="anim04-2">
                <Anim04 />
            </div>
            <div className="input__container3">
                <label htmlFor="emoji">
                    <div className="emoji__selector">
                        <div className="emoji__container">
                            {/* EMOJIS */}
                        <EmojiFeliz
                            className={selectedEmoji === "Feliz" ? "selected" : ""}
                            onClick={() => {
                            setSelectedEmoji("Feliz");
                            setSelectedEmojiText(
                                "Cuéntanos, ¿por qué te siente feliz?, y qué podemos hacer para mejorar?"
                            );
                            }}
                        />
                        <EmojiPensativo
                            className={selectedEmoji === "Pensativo" ? "selected" : ""}
                            onClick={() => {
                            setSelectedEmoji("Pensativo");
                            setSelectedEmojiText(
                                "Cuéntanos, ¿por qué te siente pensativo?, y qué podemos hacer para mejorar?"
                            );
                            }}
                        />
                        <EmojiTriste
                            className={selectedEmoji === "Triste" ? "selected" : ""}
                            onClick={() => {
                            setSelectedEmoji("Triste");
                            setSelectedEmojiText(
                                "Cuéntanos, ¿por qué te siente triste?, y qué podemos hacer para mejorar?"
                            );
                            }}
                        />
                        <EmojiEnojado
                            className={selectedEmoji === "Enojado" ? "selected" : ""}
                            onClick={() => {
                            setSelectedEmoji("Enojado");
                            setSelectedEmojiText(
                                "Cuéntanos, ¿por qué te siente enojado?, y qué podemos hacer para mejorar?"
                            );
                            }}
                        />
                        </div>
                        <div className="text__container">
                        <h1 className={`selected-emoji-text ${selectedEmoji ? "" : "hidden"}`}>
                            {selectedEmojiText}
                        </h1>
                        <textarea
                            value={selectedEmojiText}
                            onChange={(event) => setSelectedEmojiText(event.target.value)}
                            // placeholder={selectedEmojiText}
                            onFocus={handleTextAreaClick}
                        />
                        </div>
                    <div className="audio-recorder">
                    <AudioRecorder onAudioRecorded={setVoiceNoteUrl} />
                    </div>
                </div>
                </label>
            </div>
            </div>

            <div className="row">
            <div className="btn__container5">
                <button
                onClick={()=>(setEtapa(4))}
                disabled={!selectedEmoji} // Deshabilitar el botón si selectedEmoji está vacío
                style={{
                    background: "none",
                    border: "none",
                    padding: "0",
                    cursor: "pointer",
                }}
                >
                <svg
                    version="1.1"
                    baseProfile="tiny"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 218 120"
                    overflow="visible"
                    width="300px"
                    height="200px"
                >
                    <path
                    fill="#F4B223"
                    stroke="#E36F1E"
                    strokeWidth="5"
                    strokeMiterlimit="10"
                    d="M135.4,12.7c-6.2,0-11.3,5.1-11.3,11.3v6.4
                                            l-95.6-4.5c-0.2,0-0.4,0-0.5,0c-6.2,0-11.3,5.1-11.3,11.3v45.7c0,6.2,5.1,11.3,11.3,11.3c0.2,0,0.4,0,0.5,0l95.6-4.5V96
                                            c0,6.2,5.1,11.3,11.3,11.3c2.2,0,4.4-0.6,6.2-1.9l54.6-36c3.2-2.1,5.1-5.6,5.1-9.4c0-3.8-1.9-7.3-5.1-9.4l-54.6-36
                                            C139.8,13.3,137.7,12.7,135.4,12.7z"
                    />
                    <text
                    x="50%"
                    y="50%"
                    textAnchor="middle"
                    style={{
                        whiteSpace: "pre-line",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                        fill: "#000",
                        dominantBaseline: "middle",
                        cursor: "pointer",
                        padding: "1px",
                    }}
                    className="speech-bubble__text"
                    >
                    Siguiente
                    </text>
                </svg>
                </button>
            </div>
            </div>
        </>
        )}

        {/* SEXTO RENDERIZADO CONDICIONAL*/}

        {etapa === 4 && (
        <>
            {!isMuted &&(
                <audio autoPlay preload="auto">
            <source src={Voice7} type="audio/wav" />
            </audio>
            )}
            <div className="speech-bubble-6">
                <svg
                    version="1.1"
                    baseProfile="tiny"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 231 166"
                    overflow="visible"
                >
                    <path
                    fill="#E36F1E"
                    d="M7,65.3C7,31.7,63.9,4.5,134,4.5s127,27.2,127,60.8S204.1,126,134,126c-14.1,0-27.6-1.1-40.3-3.1
                                    c0.1,8.5,4.4,15.4,8.4,20.1c2,2.4,0,6-3.1,5.5c-32.5-5.7-46.8-27.9-52.1-38.9C22.4,98.4,7,82.7,7,65.3z"
                    />
                    <text
                    x="60%"
                    y="20%"
                    textAnchor="middle"
                    style={{ whiteSpace: "pre-line", fontSize: "1.1rem" }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                    >
                    Muchas gracias, dale 
                    </text>
                    <text
                    x="60%"
                    y="40%"
                    textAnchor="middle"
                    style={{ whiteSpace: "pre-line", fontSize: "1.1rem" }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                    >
                    clic en finalizar para que tus
                    </text>
                    <text
                    x="60%"
                    y="60%"
                    textAnchor="middle"
                    style={{ whiteSpace: "pre-line", fontSize: "1.1rem" }}
                    dominantBaseline="middle"
                    fill="#fff"
                    className="speech-bubble__text"
                    >
                    datos sean guardados.
                    </text>
                </svg>
            </div>
            <div className="row2">
                <div className="anim01-02">
                    <Anim01 />
                </div>
            </div>
            <div className="row">
                <div className="alert-message-box" style={{ display: alertSubmit ? 'flex' : 'none' }}>
                        <p>¡Datos enviados exitosamente!</p>
                        <Link to="/dashboard">
                            <button className="alert-message-button" onClick={() => setAlertSubmit(false)}>Aceptar</button>
                        </Link>
                    </div>
                <div className="btn__container6">
                    <button
                    onClick={handleSubmit}
                    style={{
                        border: "4px solid #f4b122",
                        borderRadius: "45px",
                        width: "45%",
                        padding: "20px",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#f4b122",
                        background: "#f4b122",
                        color: "#000000",
                        fontSize: "25px",
                    }}
                    disabled={isSubmitting}
                    >
                    Finalizar
                    </button>
                </div>
            {age >= 12 && (
                <>
                    <p className="denuncia">En caso de fraude, acoso, abuso sexual o comportamientos poco éticos, denuncia en: </p><br/><br/>
                    {/* <div className="arrow-down"></div> */}
                    <button className='denuncia-button' onClick={() => window.open("https://www.care.ethicspoint.com", "denuncia", "width=800,height=600")}>
                        Denuncia
                    </button>
                </>
            )}

            </div>
        </>
        )}
    </AutoSubmitForm>
    <div className="row">
        {showFooter && (
        <div className="footer__container">
        <button className={isMuted ? 'mute-button2 muted' : 'mute-button2'} onClick={() => toggleMute()}>{isMuted ? 'Activar sonido' : 'Silenciar'}</button>
            <Footer />
        </div>
        )}
    </div>
</div>
    );
}

export default Screen2;


export function useName() {
    const [name, setName] = useState("");
    
        return [name, setName];
    }
    
    export function usePhoneNumber() {
        const [phoneNumber, setPhoneNumber] = useState("");
    
        return [phoneNumber, setPhoneNumber];
    }