import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function Footer() {

return (
    <div className='footer-container'>
        <Link to="/dashboard">
            <button className='btn-restart'>Reiniciar</button>
        </Link>
        <svg version="1.1" baseProfile="tiny" id="Capa_1" x="0px" y="0px" viewBox="0 0 768 364" overflow="visible">
            <path fill="#EEE9E5" d="M0,23.1c58.5,17.7,128.3,18,194.7,16.3s134.7-5,197.8,6.6c53.2,9.8,98.8,29.5,150.3,41.8
            c70.7,16.8,152.2,18.8,225.2,5.5V364H0V23.1z"/>
        </svg>
        {/*en tamaño pequeño
        logo
        contacto pag web
        denuncias */}
    </div>

)
}

export default Footer