import "../css/Dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import React, { useEffect, useState } from "react";
import { auth, db } from "../firebase";
import { query, collection, getDocs, where } from "firebase/firestore";
import Logo from '../components/Logo';
import Banner from '../components/Banner';
import Footer from '../components/Footer';

function Dashboard() {
    const [user, loading] = useAuthState(auth);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const navigate = useNavigate();
    const [showFooter, setShowFooter] = useState(true);
    const [showFullText, setShowFullText] = useState(false);
    const [checked, setChecked] = useState(false);

    const handleVerMasClick = () => {
      setShowFullText(!showFullText);
    };

    const handleCheckboxClick = () => {
      setChecked(!checked);
    };

    //Revisa la orientación del dispositivo

useEffect(() => {
    function handleOrientationChange() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait) {
        alert("Por favor cambiar la orientación de la tableta");
        // document.body.style.display = "none";
      } else {
        // document.body.style.display = "block";
      }
    }
  
    handleOrientationChange();
    window.addEventListener("orientationchange", handleOrientationChange);
  
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);
  
//   Listen for changes in screen orientation
  useEffect(() => {
    function handleResize() {
      const isPortrait = window.matchMedia("(orientation: portrait)").matches;
      if (!isPortrait) {
        alert("Por favor cambiar la orientación de la tableta");
        // document.body.style.display = "none";
      } else {
        // document.body.style.display = "block";
      }
    }
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


const fetchUserName = async () => {
//   const uid = user && user.uid;
const uid = "tT7ug3xg19d0h9YWjTivaJ0cWcY2";
  const q = query(collection(db, "users"), where("uid", "==", uid));
  const querySnapshot = await getDocs(q);
  const userData = [];
  querySnapshot.forEach((doc) => {
    userData.push(doc.data());
  });

  return userData;
};

useEffect(() => {
    // write query to get all data from users collection
    const q = query(collection(db, "users"));
    getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
            const data = doc.data();
        });
    });

  fetchUserName()
    .then((userData) => {
      setName(userData[0].name);
      setMail(userData[0].email);
      console.log(userData[0].name, userData[0].email);
    })
    .catch((error) => {
      console.error(error);
    });
}, []);

useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/login");
    fetchUserName();
}, [user, loading]);

useEffect(() => {
    const handleResize = () => {
    if (window.innerHeight < height) {
        setShowFooter(false);
    } else {
        setShowFooter(true);
    }
    };

    const height = window.innerHeight;

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
}, []);

return (
    <div className="dashboard__container dark-mode">
        <div className="banner__container">
            <Banner />
        </div>
        <div className="row">
            <div className="logo1">
                <Logo />
            </div>
            <button className={checked ? 'btn' : 'btn disabled'} onClick={() => navigate('/screen1')} disabled={!checked}>
                <h1>
                Presiona aquí<br/> para comenzar
                </h1>
            </button>
            {/* <p className="version">v.1.1</p> */}
            <h2 className="datos-personales">
            Consentimiento para el uso de datos personales
            <input type="checkbox" id="checkbox" onChange={() => setChecked(!checked)} />
            <br/>
            {checked ? '' : ' (Debe seleccionar el checkbox)'}
            <br/>
            
            <button onClick={handleVerMasClick}>{showFullText ? "Cerrar" : "Ver más"}</button>
            {showFullText && (
        <div>
            <p className="datos-personales">
            Continuar con el siguiente formulario expresa CONSENTIMIENTO para el registro y uso de mis datos personales según consta en el Art.8 de la Ley Orgánica de protección de datos personales.
            CARE ECUADOR dará el uso de los datos registrados para fines exclusivos de sus proyectos de desarrollo y en conformidad al Art 9. De la Ley orgánica de protección de datos personales.<br/>Quinto suplemento, No 459 Registro Oficial, con fecha 21 Mayo 2021
            {/* <a href="assets/Ley-Organica-de-Datos-Personales.pdf" target="_blank">
                    Cliquea aquí para ver el texto completo:
            </a> */}
                </p>
        </div>
        )}
  
                
            </h2>
        </div>
        <div>
            {showFooter && (
                <div className="footer__container">
                <Footer />
            </div>
            )}
        </div>
            
    </div>
);
}

export default Dashboard;