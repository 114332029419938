import { initializeApp } from "firebase/app";
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    query,
    getDocs,
    where,
    getFirestore,
    collection,
    addDoc,
    orderBy
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL, getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCqv5u22MTEA4tKD4D08TDw8vMPKXHpPUA",
  authDomain: "criss-assist-v2-77076.firebaseapp.com",
  projectId: "criss-assist-v2-77076",
  storageBucket: "criss-assist-v2-77076.appspot.com",
  messagingSenderId: "260424046654",
  appId: "1:260424046654:web:6f6ac4c731c0da3344d96f",
  measurementId: "G-RSX77WRQGK"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const storage = getStorage(app);
const db = getFirestore(app);
// db.enablePersistence().catch(function(err) {
//     if (err.code == 'failed-precondition') {
//         // Multiple tabs open, persistence can only be enabled
//         // in one tab at a time.
//         // ...
//     } else if (err.code == 'unimplemented') {
//         // The current browser does not support all of the
//         // features required to enable persistence.
//         // ...
//     }
// });

const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};
const registerWithEmailAndPassword = async (name, email, password, role) => {
try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
    uid: user.uid,
    name,
    authProvider: "local",
    email,
    role
    });
} catch (err) {
    console.error(err);
    alert(err.message);
}
};

const isAdmin = async (uid) => {
    const q = query(collection(db, "users"), where("uid", "==", uid));
    const doc = await getDocs(q);
    if (doc.docs.length > 0) {
        const data = doc.docs[0].data();
        return data.role === "admin";
    } else {
      return false; // o cualquier otro valor por defecto
    }
};

const isReport = async (uid) => {
    const q = query(collection(db, "users"), where("uid", "==", uid));
    const doc = await getDocs(q);
    if (doc.docs.length > 0) {
        const data = doc.docs[0].data();
        return data.role === "report";
    } else {
      return false; // o cualquier otro valor por defecto
    }
};  
// const isAdmin = async (uid) => {
//     const q = query(collection(db, "users"), where("uid", "==", uid));
//     const doc = await getDocs(q);
//     const data = doc.docs[0].data();
//     return data.role === "admin";
//     };
    
const fetchContactsData = async (startDate, endDate) => {
    let q = ''
    if(startDate && !endDate){
        q = query(collection(db, "contacts") ,orderBy('timestamp', "desc"),where('timestamp', '>=', new Date(startDate)))
    }
    else if(!startDate && endDate){
        q = query(collection(db, "contacts") ,orderBy('timestamp', "desc"),where('timestamp', '<=', new Date(endDate)))
    }
    else if(startDate && endDate){
        q = query(collection(db, "contacts") ,orderBy('timestamp', "desc"), 
            where('timestamp', '>', new Date(startDate)),
            where('timestamp', '<', new Date(endDate))
        );
    }
    else {
        q = query(collection(db, "contacts") ,orderBy('timestamp', "desc"))
    }

    const querySnapshot = await getDocs(q);
    let data = [];
    querySnapshot.forEach((doc) => {
    data.push(doc.data());
    });
    console.log({ data });
    return data;
};
    
    
const logout = () => {
signOut(auth);
};


// db.enablePersistence().catch(function(err) {
//     if (err.code == 'failed-precondition') {
//       // Multiple tabs open, persistence can only be enabled
//       // in one tab at a time.
//       // ...
//     } else if (err.code == 'unimplemented') {
//       // The current browser does not support all of the
//       // features required to enable persistence.
//       // ...
//     }
// });

export {
    auth,
    db,
    storage,
    ref,
    uploadBytes,
    getDownloadURL,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordResetEmail,
    logout,
    isAdmin,
    isReport,
    fetchContactsData
};
export default firebaseConfig;