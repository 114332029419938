import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Reset from "./pages/Reset";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Screen1 from './pages/Screen1';
import Screen2 from './pages/Screen2';
import Admin from "./pages/Admin";
import Report from "./pages/Report";
import './App.css';
import { MuteContext } from './utils/MuteContext';

function App() {
const [isMuted, setIsMuted] = useState(false);

const toggleMute = () => {
  setIsMuted(!isMuted);
};

  return (
    <MuteContext.Provider value={{ isMuted, toggleMute }}>
      <div className="app">
        <Router basename={process.env.PUBLIC_URL}>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/screen1" element={<Screen1 />} />
            <Route path="/screen2" element={<Screen2 />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/report" element={<Report />} />
          </Routes>
        </Router>
      </div>
    </MuteContext.Provider>
  );
}
export default App;