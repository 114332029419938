import React, { useState, useEffect } from 'react';
import { useName, usePhoneNumber } from '../pages/Screen2';

function AutoSubmitForm({ onSubmit, timeout = 600000, children }) {
  const [lastInteractionTime, setLastInteractionTime] = useState(Date.now());
  
  const [name, setName] = useName();
  const [phoneNumber, setPhoneNumber] = usePhoneNumber();

  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = Date.now();
      const timeSinceLastInteraction = now - lastInteractionTime;

      if (timeSinceLastInteraction >= timeout && name !== '' && phoneNumber !=='' ) {
        onSubmit();
        setLastInteractionTime(now);
      }
    }, 1000);

    return () => clearInterval(intervalId);
  }, [lastInteractionTime, onSubmit, timeout]);

  const handleInteraction = () => setLastInteractionTime(Date.now());

  return (
    <form onSubmit={onSubmit} onClick={handleInteraction} onKeyDown={handleInteraction}>
      {children}
    </form>
  );
}

export default AutoSubmitForm;
